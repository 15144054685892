:root {
  --white: #fff;
  --oh-orange: rgba(242, 94, 53, 1);
  --oh-white: #f5f5f5;
  --oh-off-white: #f8f8f8;
  /* for payment card */
  --oh-black: #555555;
  --oh-black-light: #717171;
  --oh-blue: #66b5de;
  --oh-teal: #95c48c;
  --oh-disabled: #d3d3d3;
  --oh-red: #ef5c34;
  --oh-green: #a3c996;
  --oh-vl-blue: rgba(102, 181, 222, 0.1);
  --oh-border-gray: rgba(0, 0, 0, 0.05);
  --oh-shadow-gray: rgba(64, 64, 64, 0.3);
  --oh-box-gray: #fafafa;
  --oh-game-black: #15202c;
  --oh-social-blue: var(--oh-social-blue);

  --oh-brand-1: rgba(242, 183, 5, 1);
  --oh-brand-vl-1: rgba(242, 183, 5, 0.1);
  --oh-brand-2: rgba(140, 86, 55, 1);
  --oh-brand-vl-2: rgba(140, 86, 55, 0.1);
  --oh-brand-3: rgba(132, 108, 217, 1);
  --oh-brand-vl-3: rgba(132, 108, 217, 0.1);
  --oh-brand-4: rgba(191, 99, 105, 1);
  --oh-brand-vl-4: rgba(191, 99, 105, 0.1);
  --oh-brand-5: rgba(147, 204, 88, 1);
  --oh-brand-vl-5: rgba(147, 204, 88, 0.1);
  --oh-brand-6: rgba(94, 174, 1, 1);
  --oh-brand-vl-6: rgba(94, 174, 1, 0.1);
  --oh-brand-7: rgba(11, 158, 217, 1);
  --oh-brand-vl-7: rgba(11, 158, 217, 0.1);
  --oh-brand-8: rgba(255, 118, 107, 1);
  --oh-brand-vl-8: rgba(255, 118, 107, 0.1);
  --oh-brand-9: rgba(68, 137, 171, 1);
  --oh-brand-vl-9: rgba(68, 137, 171, 0.1);

  --oh-bg: #ffebe5;
  --oh-v2-1: #58b7e6;
  --oh-v2-vl-1: #cdeeff;
  --oh-v2-filter-1: invert(69%) sepia(39%) saturate(828%) hue-rotate(170deg)
    brightness(92%) contrast(97%);
  --oh-v2-2: #e87d7c;
  --oh-v2-vl-2: #fdd4c9;
  --oh-v2-filter-2: invert(76%) sepia(16%) saturate(6315%) hue-rotate(308deg)
    brightness(96%) contrast(89%);
  --oh-v2-3: #07689f;
  --oh-v2-vl-3: #c8e9fc;
  --oh-v2-filter-3: invert(18%) sepia(84%) saturate(2844%) hue-rotate(186deg)
    brightness(100%) contrast(95%);
  --oh-v2-4: #726a93;
  --oh-v2-vl-4: #e0dcf4;
  --oh-v2-filter-4: invert(45%) sepia(10%) saturate(1243%) hue-rotate(211deg)
    brightness(93%) contrast(92%);
  --oh-v2-5: #709fb0;
  --oh-v2-vl-5: #cae2eb;
  --oh-v2-filter-5: invert(60%) sepia(48%) saturate(265%) hue-rotate(150deg)
    brightness(92%) contrast(83%);
  --oh-v2-6: #b83b5e;
  --oh-v2-vl-6: #ffd1de;
  --oh-v2-filter-6: invert(30%) sepia(50%) saturate(1940%) hue-rotate(311deg)
    brightness(86%) contrast(85%);
  --oh-v2-7: #3e978b;
  --oh-v2-vl-7: #cae8e4;
  --oh-v2-filter-7: invert(51%) sepia(74%) saturate(304%) hue-rotate(122deg)
    brightness(88%) contrast(88%);
  --oh-v2-8: #5e6f64;
  --oh-v2-vl-8: #d4e4da;
  --oh-v2-filter-8: invert(40%) sepia(21%) saturate(258%) hue-rotate(88deg)
    brightness(96%) contrast(86%);
  --oh-v2-9: #6f4a8e;
  --oh-v2-vl-9: #e4d9ed;
  --oh-v2-filter-9: invert(33%) sepia(9%) saturate(3158%) hue-rotate(230deg)
    brightness(92%) contrast(84%);
  /* --oh-v2-10: #95c48c; */
  --oh-v2-10: #77b36c;
  --oh-v2-vl-10: #dcf3d8;
  --oh-v2-filter-10: invert(78%) sepia(17%) saturate(619%) hue-rotate(64deg)
    brightness(92%) contrast(88%);

  /* for mun club */
  --oh-v2-11: #d9ecf7;
  --oh-club-yellow: #f3c520;
  --oh-demo-brown: #b98139;
  --oh-demo-brown-vl: #f4e5d2;
  --oh-demo-brown-filter: invert(52%) sepia(77%) saturate(370%)
    hue-rotate(354deg) brightness(89%) contrast(89%);

  --oh-lobby-social: #92d0b9;
  --oh-lobby-background: #fff;
  /* FIXME: */

  --oh-default-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
}

a {
  text-decoration: none;
  color: unset;
}

html {
  overflow-y: scroll;
}

body {
  margin: 0;
  background-color: var(--oh-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slide {
  margin-right: 15px;
  /* width: 125px !important; */
}

.slick-arrow {
  width: 2.5rem !important;
  height: 2.5rem !important;
  cursor: pointer !important;
}

.slick-prev {
  left: -90px !important;
}

.slick-next {
  right: -85px !important;
}

.slick-disabled {
  display: none !important;
}

.slick-track {
  display: flex !important;
}

.fadeout {
  width: 100%;
  height: 100%;
  /* opacity: 0; */
}

@media (max-width: 1366px) {
  .slick-slide {
    margin-right: 15px;
    /* width: 100px !important; */
  }
}

@media (max-width: 456px) {
  .slick-prev {
    left: -70px !important;
  }

  .slick-next {
    right: -70px !important;
  }
}

/* Scrollbar customizations currently only for webkit engine */

::-webkit-scrollbar {
  width: 7px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  width: 7px;
  border-radius: 5px;
  background-color: #999dad;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

/* Scrollbar customizations */

@media (max-width: 920px) {
  .slick-slide {
    margin-right: 0;
  }
}
