@font-face {
  font-family: Avenir;
  src: local('Avenir Next'), url('./assets/fonts/AvenirReg.ttf');
  font-display: swap;
}

@font-face {
  font-family: Avenir Next Rounded Std;
  src: url('./assets/fonts/AvenirNextRoundedStdReg.ttf');
  font-display: swap;
}

@font-face {
  font-family: Avenir Next Rounded Std-MedIt;
  src: url('./assets/fonts/AvenirNextRoundedStd-MedIt.ttf');
  font-display: swap;
}

@font-face {
  font-family: Avenir Next Rounded Std-Demi;
  src: url('./assets/fonts/AvenirNextRoundedStd-Demi.ttf');
  font-display: swap;
}
@font-face {
  font-family: AvenirMed;
  src: local('Avenir Next'), url('./assets/fonts/AvenirMed.ttf');
  font-display: swap;
}
@font-face {
  font-family: AvenirDemi;
  src: local('Avenir Next'), url('./assets/fonts/AvenirDemi.ttf');
  font-display: swap;
}

html {
  font-size: 16px;
}

body {
  font-family: Avenir;
}

.bold-font {
  font-family: Avenir Next Rounded Std-Demi;
}

@media screen and (max-width: 319px), handheld and (max-width: 319px) {
  html {
    font-size: 11px;
  }
}

@media screen and (min-width: 320px) and (max-width: 399px),
  handheld and (min-width: 320px) and (max-width: 399px) {
  html {
    font-size: 11.5px;
  }
}

@media screen and (min-width: 400px) and (max-width: 479px),
  handheld and (min-width: 400px) and (max-width: 479px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 480px) and (max-width: 639px),
  handheld and (min-width: 480px) and (max-width: 639px) {
  html {
    font-size: 12.5px;
  }
}

@media screen and (min-width: 640px) and (max-width: 767px),
  handheld and (min-width: 640px) and (max-width: 767px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) and (max-width: 959px),
  handheld and (min-width: 768px) and (max-width: 959px) {
  html {
    font-size: 13.5px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1023px),
  handheld and (min-width: 960px) and (max-width: 1023px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px),
  handheld and (min-width: 1024px) and (max-width: 1199px) {
  html {
    font-size: 14.5px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1279px),
  handheld and (min-width: 1200px) and (max-width: 1279px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1365px),
  handheld and (min-width: 1280px) and (max-width: 1365px) {
  html {
    font-size: 15.5px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1439px),
  handheld and (min-width: 1366px) and (max-width: 1439px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px),
  handheld and (min-width: 1440px) and (max-width: 1599px) {
  html {
    font-size: 16.5px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1679px),
  handheld and (min-width: 1600px) and (max-width: 1679px) {
  html {
    font-size: 17px;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1919px),
  handheld and (min-width: 1680px) and (max-width: 1919px) {
  html {
    font-size: 17.5px;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2047px),
  handheld and (min-width: 1920px) and (max-width: 2047px) {
  html {
    font-size: 18px;
  }
}

@media screen and (min-width: 2048px) and (max-width: 2559px),
  handheld and (min-width: 2048px) and (max-width: 2559px) {
  html {
    font-size: 18.5px;
  }
}

@media screen and (min-width: 2560px) and (max-width: 2879px),
  handheld and (min-width: 2560px) and (max-width: 2879px) {
  html {
    font-size: 19px;
  }
}

@media screen and (min-width: 2880px), handheld and (min-width: 2880px) {
  html {
    font-size: 19.5px;
  }
}
